<template>
    <div class="pay-content">
        <div class="con">
            <div class="brand">
                <el-breadcrumb>
                    <el-breadcrumb-item>首页</el-breadcrumb-item>
                    <el-breadcrumb-item>购物车</el-breadcrumb-item>
                    <el-breadcrumb-item>确认订单</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="address">
                <div class="address-top">
                    <div class="left-con">
                        <span>收货方式</span>
                        <div class="btn-con">
                            <div class="btn" :class="chooseType?'active':''" @click="choose(1)">
                                快递
                            </div>
                            <div class="btn" :class="!chooseType?'active':''" @click="choose(2)">
                                自提
                            </div>
                        </div>
                    </div>
                    <div class="right-con" v-if="chooseType" @click="addAddress">
                        添加收货地址
                        <el-icon><plus /></el-icon>
                    </div>
                    
                </div>

                <div class="address-center" v-if="chooseType">
                    <template v-for="item in address_list" :key="item.id" >
                        <div class="address-item" :class="item.is_default ==1?'active':''">
                            <div class="ad-top">
                                <div class="t-left">{{item.name}}</div>
                                <div class="tel">{{item.tel}}</div>
                            </div>
                            <div class="ad-center">
                                <div class="address-name">{{item.address_xq}}</div>
                                <p>{{item.name}}</p>
                            </div>

                            <div class="ad-bottom">
                                <div class="left-con" :class="item.is_default ==1?'actives':''" @click="setDefault(item.id)">
                                   设为默认
                                </div>
                                <div class="right-con">
                                    <div class="btn" style="margin-right: 10px;" @click="modifyAddr(item)">
                                        修改
                                    </div>
                                    <div class="btn" @click="removeAddr(item.id)">
                                        删除
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="address-item addAddress" @click="addAddress">
                        <el-icon size="32"><plus /></el-icon>
                        <span>添加收货地址</span>
                    </div>
                </div>
            </div>

            <div class="payType">
                <div class="pay-top">
                    支付方式
                </div>
                <div class="pay-bottom">
                    <div class="item" :class="payType ==1 ?'active':''" @click="payType=1">
                        <img src="@/assets/image/wx_pay.png" />
                    </div>
                    <div class="item" :class="payType ==2 ?'active':''" @click="payType=2">
                        <img src="@/assets/image/ali_pay.png" />
                    </div>
                </div>
            </div>

            <div class="good-info">
                <div class="good-top">
                    <div class="title">确认商品信息</div>
                    <div class="right-con">
                        <span>返回购物车修改</span><el-icon size="16"><arrow-right /></el-icon>
                    </div>
                </div>

                <div class="prodetail">
                   <div class="item" style="width:460px;">商品信息</div>
                   <div class="item">单价</div>
                   <div class="item">数量</div>
                   <div class="item">金额</div>
                </div>

                <ul class="inforTable" v-for="(items,indexs) in products" :key="indexs">
                    <li class="shop-name">{{items.shop_name}}</li>
                    <li v-for="(item,index) in items.list" :key="index">
                        <div class="inforTable_name">
                            <img :src="item.img" alt="">
                            <div class="con1">
                                <p>{{item.product_title}}</p>
                                <span>{{item.size}}</span>
                            </div>
                        </div>
                        <div class="inforTable_price">￥{{item.price}}</div>
                        <div class="inforTable_price">x{{item.num}}</div>
                        <div class="inforTable_price" style="font-weight:bold;">￥{{(item.price*item.num).toFixed(2)}}</div>
                    </li>
                    <li class="last-part">
                        <div class="mchyhq">
                            店铺优惠：
                            <el-select v-if="items.coupon_list.length>0" v-model="mch_sale[indexs]" placeholder="请选择店铺优惠">
                                <el-option v-for="item in items.coupon_list" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id"></el-option>
                            </el-select>
                            <template v-else>暂无可用优惠券</template>
                            <p class="mchfreight" v-if="is_freight">运费：{{items.freight_price == 0?'免邮': ('￥' + items.freight_price)}}</p>
                        </div>
                        
                        <div class="mchprice">
                            共&nbsp;<span>{{items.products_num}}</span>&nbsp;件商品，合计：<span>￥{{items.shop_subtotal}}</span>
                        </div>
                    </li>
                </ul>


                <div class="priceBox">
                    <div class="coupon">
                        <div class="coupon_top">
                            平台优惠券
                            <span>{{coupon_num?coupon_num:0}}张</span>
                        </div>

                        <el-select v-if="coupon_list.length>0" v-model="coupon_id" placeholder="请选择平台优惠" size="mini" style="width:70px;border-radius:0px;">
                            <el-option v-for="item in coupon_list" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id+','+item.discount_type"></el-option>
                        </el-select>
                        <template v-else>暂无可用优惠券</template>
                    </div>
                    <ul>
                        <li>
                            商品总价：
                            <p>￥{{products_total}}</p>
                        </li>
                        <li >
                            店铺优惠：
                            <p>-￥{{mch_preferential_amount}}</p>
                        </li>
                        <li >
                            平台优惠：
                            <p>-￥{{preferential_amount}}</p>
                        </li>
                        <li >
                            会员折扣优惠：
                            <p>-￥{{grade_rate_amount}}</p>
                        </li>
                        <li>
                            应付金额：
                            <p style="font-weight: bold;color: #D4282D;">￥{{total}}</p>
                        </li>
                    </ul>
                </div>


                <div class="payBottom">
                    <div class="payBottom_top">
                        应付金额：<span>￥{{total}}</span>
                        <div class="payBtn" @click="saveOk">提交订单</div>
                    </div>

                    <template v-for="item in address_list">
                        <p v-if="addressId == item.id" :key="item.id" style="margin-top: 20px;">寄送至：{{item.sheng}}&nbsp;{{item.city}}&nbsp;{{item.quyu}}&nbsp;{{item.address}}</p>
                        <p v-if="addressId == item.id" :key="index+'-'+item.id">收货人：{{item.name}}&nbsp;&nbsp;{{item.tel.substr(0,3)+'****'+item.tel.substr(7,11)}}</p>
                    </template>
                </div>
            </div>
        </div>



        <el-dialog v-model="diavisible" :title="addlabel?'新增收货地址':'编辑收货地址'" width="35%">
            <el-form :model="form" label-width="120px" label="left">
                <el-form-item label="所在地址">
                    <el-cascader
                        v-model="form.place"
                        :options="addressOptions"
                        placeholder="请选择省/市/区"
                        size="medium"
                        style="width:100%;"
                        ></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址">
                   <el-input
                        v-model="form.address"
                        :rows="2"
                        type="textarea"
                        placeholder="请输入信息地址信息，如小区名称、楼栋号、单元等信息"
                    />
                </el-form-item>
                <el-form-item label="收货人">
                   <el-input
                        v-model="form.user_name"
                        placeholder="请输入收货人姓名"
                    />
                </el-form-item>
                <el-form-item label="手机号码">
                   <el-input
                        v-model="form.mobile"
                        placeholder="请输入收货人姓名"
                    />
                </el-form-item>
                <el-form-item>
                   <div>
                        <el-checkbox v-model="checkbool">设为默认地址</el-checkbox>
                    </div>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="foot-con">
                    <el-button @click="diavisible =false" size="mini">取消</el-button>
                    <el-button type="danger" @click="saveAddress" size="mini" v-if="addlabel">确定</el-button>
                    <el-button type="danger" @click="saveAddress1" size="mini" v-else>确定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {ref,onMounted,reactive, toRefs} from 'vue'
import { Plus,ArrowRight } from '@element-plus/icons'
import {useRoute,useRouter} from 'vue-router'
import {getData} from '@/utils/req'
import { ElMessage } from 'element-plus'
export default {
    components:{
        Plus,
        ArrowRight
    },
    setup(){
        const route = useRoute()
        const router = useRouter()
        let pid = ref('')
        let cid = ref('')
        let num = ref('')
        let pc_user = ref('')
        let addressId = ref('')
        let canshu = ref('')
        let payType = ref(1)
        let is_freight = ref(true)
        let diavisible = ref(false)
        let addlabel = ref(true)
        let addressOptions = ref('')

        let cartId = ref('')

        let order_list = ref('')
        let form = reactive({
            place:'',
            user_name:'',
            address:'',
            mobile:'',
            is_default:0,
            addr_id:''
        })
        let checkbool = ref(false)
        let state = reactive({
            current_address:'',
            address_list:'',
            freight:'',
            products_total:'',
            reduce_money:'',
            products:'',
            coupon_list:'',
            mch_preferential_amount:'',
            preferential_amount:'',
            grade_rate_amount:'',
            total:''
        })
        onMounted(()=>{
            pid.value = route.query.pid,
            cid.value = route.query.cid
            num.value = route.query.num
            cartId.value = route.query.cart_id
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
            getAddress()
        })
        let chooseType =ref(true)
        const choose = () =>{
            chooseType.value = !chooseType.value
        }

        //获取地址信息
        const getAddress = () =>{
            const data ={
                module: 'app_pc',
                action: 'address',
                m: 'AddressManagement',
                access_id:pc_user.value.access_id
            }
            getData(data).then(res=>{
                if(res.code ==200){
                    addressOptions.value = res.data
                }
            })
        }
        const axios =() =>{
            if(!cartId.value)
            {
                var product = []
                product.push({
                    pid:pid.value
                })
                product.push({
                    cid: cid.value
                })
                product.push({
                    num: num.value
                })
                product = JSON.stringify(product)
            }
            const data = {
                module: 'app_pc',
                action: 'order',
                m: 'Settlement',
                access_id: pc_user.value.access_id,
                product_type: '', 
                address_id: addressId.value, // 地址id
                canshu: canshu.value,
                product:product,
                cart_id:cartId.value
            }
            getData(data).then(res =>{
                if(res.code ==200){
                    state.address_list = res.data.address_list
                    state.current_address = res.data.address
                    state.products = res.data.products
                    state.freight = res.data.freight
                    state.coupon_list = res.data.coupon_list
                    state.mch_preferential_amount = res.data.mch_preferential_amount
                    state.preferential_amount = res.data.preferential_amount
                    state.grade_rate_amount = res.data.grade_rate_amount
                    state.total = res.data.total
                    state.products_total = res.data.products_total
                    addressId.value = res.data.address.id
                }
            })
        }
        //删除地址
        const removeAddr = (id) =>{
            const data ={
                module: 'app_pc',
                action: 'address',
                m: 'del_adds',
                access_id: pc_user.value.access_id,
                addr_id:id
            }
            getData(data).then(res=>{
                if(res.code){
                    ElMessage.success({
                        message:'成功删除地址',
                        duration:1500
                    })
                    axios()
                }
            })
        }
        const saveAddress =() =>{
            let data = {
                module: 'app_pc',
                action: 'address',
                m: 'SaveAddress',
                access_id:pc_user.value.access_id
            }
            if(checkbool.value){
                form.is_default = 1
            }
            form.place = form.place.join(',')
            data = Object.assign(data,form)
            if(!form.user_name){
                ElMessage.info({
                    message:'用户名不能为空',
                    duration:1500
                })
                return
            }
            if(!form.address){
                ElMessage.info({
                    message:'详情地址不能为空',
                    duration:1500
                })
                return 
            }
            if(!form.mobile){
                ElMessage.info({
                    message:'手机号不能为空',
                    duration:1500
                })
                return
            }
            if(!form.place){
                ElMessage.info({
                    message:'请选择收货地址',
                    duration:1500
                })
            }
            getData(data).then(res=>{
                if(res.code ==200){
                    ElMessage.success({
                        message:'地址添加成功',
                        duration:1500
                    })
                    axios()
                    diavisible.value = false
                }
            })
        }
        //设置默认地址
        const setDefault = (id) =>{
            const data ={
                module: 'app_pc',
                action: 'address',
                m: 'set_default',
                access_id: pc_user.value.access_id,
                addr_id: id
            }
            getData(data).then(res=>{
                if(res.code ==200){
                    state.address_list = res.data
                    axios()
                }
            })
        }
        //添加收货地址
        const addAddress =() =>{
            diavisible.value = true
        }
        //修改收货地址
        const modifyAddr =(item) =>{
            diavisible.value = true
            addlabel.value = false
            form.user_name = item.name
            form.address = item.address
            form.mobile = item.tel
            form.addr_id = item.id
        }
        //提交订单
        const saveOk = () =>{
            var data = {
                module: 'app_pc',
                action: 'order',
                m: 'payment',
                access_id: pc_user.value.access_id,
                grade_l: "0",
                type: '',
                coupon_id: '0,0,0',
                allow: 0, 
                address_id: addressId.value, 
                remarks: '', 
                pay_type: '',
                shop_address_id:'',
                cart_id:cartId.value    //购物车结算方式
            }
            if(payType.value == 1){
                data.pay_type = 'pc_wechat'
            }
            else if(payType.value ==2){
                data.pay_type = 'pc_alipay'
            }

            if(!cartId.value){
                var product = []
                product.push({
                    pid:pid.value
                })
                product.push({
                    cid: cid.value
                })
                product.push({
                    num: num.value
                })

                product = JSON.stringify(product)
                data.product = product
            }
            
            getData(data).then(res=>{
                order_list.value = res.data
                if (payType.value == 1) {
                    let payData = {
                        module: 'app_pc',
                        action: 'pay',
                        access_id:pc_user.value.access_id,
                        remarks: '', //  订单备注
                        title: state.products[0].list[0].product_title,
                        total:order_list.value.total,
                        order_list: order_list.value,
                        type: 'pc_wechat', //  支付类型
                    }
                    window.localStorage.setItem('payData', JSON.stringify(payData))
                }
                else if (payType.value ==2) {
                    let payData = {
                        module: 'app_pc',
                        action: 'pay',
                        access_id: pc_user.value.access_id,
                        total: order_list.value.total, //会员特惠 兑换券级别
                        remarks: '', //  订单备注
                        title: state.products[0].list[0].product_title,
                        order_list: order_list.value,
                        type: 'pc_alipay', //  支付类型
                    }
					window.localStorage.setItem('payData', JSON.stringify(payData))
                }
                router.push({
                    path:'/pay'
                })
            })
        }
        const saveAddress1 = () =>{
            let data= {
                module: 'app_pc',
                action: 'address',
                m: 'up_adds',
                access_id: pc_user.value.access_id
            }
            if(!form.place){
                ElMessage.info({
                    message:'请选择收货地址',
                    duration:1500
                })
                return
            }
            form.place = form.place.join(',')
            data = Object.assign(data,form)
            getData(data).then(res=>{
                if(res.code ==200){
                    ElMessage.success({
                        message:'用户地址修改成功',
                        duration:1500
                    })
                    diavisible.value = false
                    axios()
                }
            })
        }
        return{
            chooseType,
            payType,
            is_freight,
            diavisible,
            addlabel,
            addressOptions,
            form,
            checkbool,
            addressId,
            choose,
            ...toRefs(state),
            setDefault,
            addAddress,
            saveAddress,
            removeAddr,
            modifyAddr,
            saveAddress1,
            saveOk
        }
    }
}
</script>
<style scoped lang="scss">
    .pay-content{
        width: 100%;
        .con{
            width: 1200px;
            margin:30px auto 0;
            .address{
                width: 100%;
                .address-top{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 40px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid #eee;
                    .left-con{
                        display: flex;
                        align-items: center;
                        span{
                            font-size: 16px;
                            line-height: 16px;
                            color: #020202;
                            font-weight: bold;
                        }
                        .btn-con{
                            display: flex;
                            margin-left: 10px;
                            .btn{
                                width: 100px;
                                height: 30px;
                                background: #FFFFFF;
                                border: 1px solid #DDDDDD;
                                font-size: 14px;
                                font-weight: bold;
                                color: #020202;
                                cursor: pointer;
                                outline: none;
                                text-align: center;
                                line-height: 30px;
                            }
                            .btn:last-child{
                                margin-left: 10px;
                            }
                        }
                    }
                }
                .address-center{
                    display: flex;
                    flex-wrap: wrap;
                    padding: 24px 0;
                    .address.active::after{
                        position: absolute;
                        content: '';
                        display: block;
                        right:0px;
                        bottom: 0px;
                        background-image: url('../../assets/image/gouxuan.png');
                    }
                    .address-item:not(:last-child){
                        margin-right: 24px;
                    }
                    .address-item{
                        display: flex;
                        flex-direction: column;
                        width: 280px;
                        height: 160px;
                        border: 1px solid rgba(230, 230, 230, 1);
                        border-top: 2px solid rgba(230, 230, 230, 1);
                        padding: 0 10px;
                        box-sizing: border-box;
                        cursor: pointer;
                        position: relative;
                        margin-bottom: 10px;
                        .ad-top{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 15px 0 13px;
                            font-size: 14px;
                            line-height: 14px;
                            color: #020202;
                            border-bottom: 1px solid #EEEEEE;
                            font-weight: bold;
                        }
                        .ad-center{
                            flex: 1;
                            .address-name{
                                font-size: 14px;
                                line-height: 14px;
                                color: #020202;
                                margin-top: 14px;
                            }
                            p{
                                font-size: 14px;
                                line-height: 14px;
                                color: #999999;
                                margin-top: 6px;
                                word-break: break-all;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }
                        .ad-bottom{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 10px;
                            .left-con{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 66px;
                                height: 24px;
                                background: rgba(230, 230, 230, 1);
                                font-size: 14px;
                                color: #020202;
                                margin-right: auto;
                            }
                            .right-con{
                                display: flex;
                                align-items: center;
                                font-size: 14px;
                                line-height: 14px;
                                color: #888888;
                                cursor: pointer;
                            }
                        }
                    }
                    .addAddress{
                        align-items: center;
                        justify-content: center;
                        span{
                            font-size: 14px;
                            line-height: 14px;
                            color: #888888;
                            margin-top: 20px;
                        }
                    }
                }
            }
            .payType{
                margin-top: 50px;
                .pay-top{
                    font-size: 16px;
                    line-height: 16px;
                    color: #020202;
                    font-weight: bold;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #E6E6E6;
                }
                .pay-bottom{
                    display: flex;
                    padding: 24px 0 50px;
                    .item{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 210px;
                        height: 50px;
                        margin-right: 30px;
                        cursor: pointer;
                        position: relative;
                        border: 1px solid rgba(221, 221, 221, 1);
                        box-sizing: border-box;
                    }
                    .item.active{
                        border: 1px solid #D4282D;;
                    }
                    .item.active::after{
                        position: absolute;
                        content: '';
                        display: block;
                        bottom: 0px;
                        right: 0px;
                        background-image: url('../../assets/image/gouxuan.png');
                    }
                }
            }
            .good-info{
                margin-top: 50px;
                .good-top{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #E6E6E6;
                    padding-bottom: 15px;
                    .title{
                        font-size: 16px;
                        line-height: 16px;
                        color: #020202;
                        font-weight: bold;
                    }
                    .right-con{
                        display: flex;
                        align-items: center;
                    }
                    span{
                        font-size: 14px;
                        font-weight: 300;
                        color: #D4282D;
                        cursor: pointer;
                    }
                }
            }
            .prodetail{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .item{
                    text-align: center;
                    padding: 14px 20px;
                    font-size: 14px;
                    color: #999999;
                    box-sizing: border-box;
                }
                .item:not(:first-child){
                    flex: 1;
                }
            }
            .inforTable{
                width: 100%;
                border: 1px solid #E6E6E6;
                margin-bottom: 24px;
                .shop-name{
                    padding: 16px 20px;
                    background-color: #F6F6F6;
                    color: #020202;
                    font-size: 14px;
                    line-height: 14px;
                    border: 0;
                }
                li{
                    display: flex;
                    border-bottom: 1px solid #EEEEEE;
                }
                .inforTable_name{
                    width: 460px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 118px;
                    font-size: 14px;
                    color: #020202;
                    img{
                        width: 80px;
                        height: 80px;
                    }
                    .con1{
                        width: 322px;
                        overflow: hidden;
                        margin-left: 10px;
                        p{
                            font-size: 14px;
                            color: #020202;
                        }
                        span{
                            font-size: 12px;
                            color: #999999;
                        }
                    }
                    
                }
                .inforTable_price{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 118px;
                    font-size: 14px;
                    color: #020202;
                }
                .last-part{
                    align-items: center;
                    font-size: 14px;
                    line-height: 14px;
                    color: #020202;
                    padding: 20px;
                    border: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .mchyhq{
                        display: flex;
                        align-items: center;
                        p{
                            margin-left: 30px;
                        }
                    }
                }

            }
            .priceBox{
                border: 1px solid #E6E6E6;
                .coupon{
                    padding: 24px 20px;
                    .coupon_top{
                        font-size: 14px;
                        line-height: 14px;
                        color: #020202;
                        font-weight: bold;
                        margin-bottom: 15px;
                        span{
                            font-size: 12px;
                            color: #999999;
                            font-weight: 300;
                        }
                    }
                }
                ul{
                    padding: 20px;
                    background-color: #F6F6F6;
                    li{
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        height: 26px;
                        font-size: 14px;
                        color: #020202;
                    }
                }
            }
            .payBottom{
                padding: 24px 0;
                .payBottom_top{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-size: 14px;
                    color: #020202;
                    span{
                        font-size: 20px;
                        font-weight: bold;
                        color: #D4282D;
                    }
                    .payBtn{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 168px;
                        height: 50px;
                        background: rgba(212, 40, 45, 1);
                        border-radius: 2px;
                        color: #FFFFFF;
                        margin-left: 29px;
                        cursor: pointer;
                    }
                }
                p{
                    text-align: right;
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }

    .active{
        position: relative;
        color: #D0021B;
        border: 1px solid #D0021B;
    }
    .active::after{
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 24px;
        height: 24px;
        background: url('../../assets/image/gouxuan.png');
    }
    .actives{
        background-color: #D4282D!important;
        color: #FFFFFF!important;
    }
    ::v-deep .el-dialog__body{
        padding:0px;
    }
    ::v-deep .el-dialog__header{
        padding:0px;
    }
    ::v-deep .el-dialog__footer{
        text-align: center;
        padding-top: 16px;
    }
    ::v-deep .el-button{
        width: 112px;
        height: 36px;
        border-radius: 0px;
        min-height: 0px;
    }
    ::v-deep .el-button--text{
        width: 0px;
        height: 0px;
        font-size: 14px;
        color: #666;
    }
    ::v-deep .el-dialog__title{
        padding: 22px 0 22px 20px;
        display: block;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        font-weight: bold;
    }
    ::v-deep .el-dialog__header{
        border-bottom: 1px solid #e6e6e6;
    }
    ::v-deep .el-input{
        width:375px;
        border-radius: 0px;
    }
    ::v-deep .el-form{
        margin-top: 10px;
    }
    ::v-deep .el-textarea{
        width: 375px;
        border-radius: 0px;
    }
    .foot-con .el-button{
        width: 112px;
        height: 36px;
        box-sizing: border-box;
        cursor: pointer;
        margin-left: 10px;
        font-size: 16px;
        border-radius: 0;
    }
    ::v-deep .el-dialog__footer{
        height: 68px;
        background: #f6f6f6;
    }
    ::v-deep .el-input--mini{
        width: 150px;
    }
    ::v-deep .el-input__inner{
        border-radius: 0px;
    }
</style>